import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Form,
  Button,
  Col,
  Dropdown,
  Modal,
  Row,
  Image,
} from "react-bootstrap";
import { FaCircle, FaPencil, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaInfoCircle } from "react-icons/fa";

const ReleaseReq = () => {
  // const albums = [
  //   {
  //     id: "001",
  //     title: "Echoes of Silence",
  //     releaseDate: "10 January 2021",
  //     genre: "Alternative",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "002",
  //     title: "Celestial Harmony",
  //     releaseDate: "24 March 2022",
  //     genre: "Ambient",
  //     status: "Upcoming",
  //     statusClass: "warning",
  //   },
  //   {
  //     id: "003",
  //     title: "Electric Dreams",
  //     releaseDate: "15 August 2020",
  //     genre: "Synthwave",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "004",
  //     title: "Mystic River",
  //     releaseDate: "05 May 2019",
  //     genre: "Folk",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "005",
  //     title: "Future Nostalgia",
  //     releaseDate: "18 November 2023",
  //     genre: "Pop",
  //     status: "Upcoming",
  //     statusClass: "warning",
  //   },
  // ];

  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        // Fetch artistId from localStorage
        // const artistProfile = JSON.parse(localStorage.getItem("profile"));
        // const artistId = artistProfile?.artistId;

        // if (!artistId) {
        //   setError("Artist ID not found");
        //   return;
        // }

        // Call the API to fetch albums by the artist
        const response = await axios.get(`https://fttunes.com/api/albums`);
        console.log("response releases req", response.data.albums);
        const sortedAlbums = response.data.albums.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA; // Newest albums first
        });
        setAlbums(sortedAlbums); // Assuming API returns albums in `data.data`
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch albums");
        setLoading(false);
      }
    };

    fetchAlbums();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  // const handleDeleteAlbum = async (id) => {
  //   if (window.confirm("Are you sure you want to delete this album?")) {
  //     try {
  //       await axios.delete(`https://fttunes.com/api/albums/${id}`);
  //       setAlbums(albums.filter((album) => album.id !== id));
  //     } catch (error) {
  //       console.error("Error deleting album:", error);
  //     }
  //   }
  // };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this release?")) {
      try {
        await axios.delete(`https://fttunes.com/api/albums/${id}`);
        setAlbums((prevAlbums) =>
          prevAlbums.filter((album) => album.id !== id)
        );
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleChangeStatus = async (id, status) => {
    try {
      const response = await axios.put(
        `https://fttunes.com/api/albums/${id}/status`,
        { status }
      );
      console.log("response of change status", response.data);

      setAlbums((prevAlbums) =>
        prevAlbums.map((album) =>
          album.id === id ? { ...album, status: response.data.status } : album
        )
      );

      setShowModal(false);
      window.location.reload();
    } catch (err) {
      setError(err.message);
    }
  };

  const openChangeStatusModal = (album) => {
    setSelectedAlbum(album);
    setShowModal(true);
  };

  const pendingAlbums = albums.filter((album) => album.status !== "Released");

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <Col className="artists-table">
      <Card>
        <Card.Header>
          {/* <Button
                  variant="primary"
                  className="theme-btn add_artist_btn"
                  // style={{ margin: "20px 10px" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button> */}
          <h4 className="card-title">
            <mark>Recent Release Request</mark>
          </h4>
          <Button
            as={Link}
            to="/add-fttrack"
            className="theme-btn add_album_btn"
          >
            Add New FTTrack
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table responsive="md" variant="dark">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>
                    <Form.Check
                      type="checkbox"
                      id="checkAllAlbums"
                      className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                      style={{ border: "none" }}
                      label=""
                    />
                  </th>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Release Date</th>
                  <th>Genre</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pendingAlbums.map((album, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`customCheckBoxAlbum${index + 2}`}
                        className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                        label=""
                        style={{ border: "none" }}
                      />
                    </td>
                    <td>
                      <strong>{album.id}</strong>
                    </td>
                    <td>
                      <Link
                        to={`/fttrack/${album.id}`}
                        style={{ textDecoration: "", color: "#fff" }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={`https://fttunes.com/api/${album.uploadPoster}`}
                            className="rounded-lg me-2"
                            width="24"
                            alt=""
                          />
                          <span className="w-space-no">{album.songName}</span>
                        </div>
                      </Link>
                    </td>
                    <td>
                      {" "}
                      {new Date(album.liveDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </td>
                    <td>{album.primaryGenre}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <FaCircle
                          className={`text-${
                            album.status === "Released"
                              ? "success"
                              : album.status === "Rejected"
                              ? "danger"
                              : album.status === "Approved"
                              ? "approved"
                              : "warning"
                          } me-1`}
                        />
                        {album.status}
                      </div>
                    </td>

                    {/* <td>
                      {album.status === "Pending" && (
                        <div className="d-flex">
                          <Button
                            variant="primary"
                            size="sm"
                            className="me-1"
                            style={{ padding: "0.625rem 1rem" }}
                            onClick={() =>
                              navigate(`/artist/releases/edit/${album.id}`)
                            }
                          >
                            <FaPencil />
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            style={{ padding: "0.625rem 1rem" }}
                            onClick={() => handleDeleteAlbum(album.id)}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      )}
                    </td> */}
                    <td>
                      <div className="d-flex">
                        <Button
                          variant="info"
                          size="sm"
                          className="me-1"
                          onClick={() => navigate(`/fttrack/${album.id}`)}
                        >
                          <FaInfoCircle />{" "}
                          {/* Or use a different info icon if desired */}
                        </Button>
                        <Button
                          variant="primary"
                          size="sm"
                          className="me-1"
                          onClick={() => navigate(`/fttrack/edit/${album.id}`)}
                        >
                          <FaPencil />
                        </Button>
                        {/* <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(artist.artistId)}
                          >
                            <FaTrash />
                          </Button> */}

                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            size="sm"
                            className="me-1"
                          >
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => openChangeStatusModal(album)}
                            >
                              Change Status
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                              // onClick={() => openUploadDocsModal(album)}
                            >
                              Upload Documents
                            </Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={() => navigate(`/fttrack/${album.id}`)}
                            >
                              View Release
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => handleDelete(album.id)}
                            >
                              Remove Release
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                                onClick={() =>
                                  handleViewNotification(artist.artistId)
                                }
                              >
                                Notification
                              </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      {selectedAlbum && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="xl"
          className="change_status_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#fff" }}>Change Status</Modal.Title>
          </Modal.Header>
          <Modal.Body className="artist-profile">
            <Row className="align-items-center">
              <Col>
                <Card className="top-bid topbidd">
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <Image
                          src={`https://fttunes.com/api/${selectedAlbum.uploadPoster}`}
                          className="img-fluid rounded"
                          alt="Item"
                        />
                      </Col>
                      <Col md={6}>
                        <div className="d-flex align-items-center mb-3">
                          <Image
                            src={`https://fttunes.com/api/${selectedAlbum.uploadPoster}`}
                            alt="Avatar"
                            className="me-3 avatar-img"
                            roundedCircle
                          />
                          <div className="flex-grow-1">
                            <h6 className="mb-0">
                              {selectedAlbum.songName}{" "}
                              {/* {selectedAlbum.lastName}{" "} */}
                              {/* <span className="circle bg-success"></span> */}
                            </h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mb-3">
                          {/* <Button variant="primary" className="me-2">
                            Add Album
                          </Button>
                          <Button variant="secondary">Remove Artist</Button> */}

                          {selectedAlbum.status !== "Approved" && (
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleChangeStatus(selectedAlbum.id, "Approved")
                              }
                            >
                              Approve
                            </Button>
                          )}

                          {selectedAlbum.status !== "Released" && (
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleChangeStatus(selectedAlbum.id, "Released")
                              }
                            >
                              Released
                            </Button>
                          )}

                          {selectedAlbum.status !== "Pending" && (
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleChangeStatus(selectedAlbum.id, "Pending")
                              }
                            >
                              Add to Pending
                            </Button>
                          )}

                          {selectedAlbum.status !== "Rejected" && (
                            <Button
                              variant="secondary"
                              onClick={() =>
                                handleChangeStatus(selectedAlbum.id, "Rejected")
                              }
                            >
                              Reject
                            </Button>
                          )}
                        </div>
                        {/* <h4 className="card-title">Music Composer</h4> */}
                        <div className="d-flex justify-content-between mt-3 mb-3">
                          <div className="text-start">
                            <p className="mb-2">Email</p>
                            <h5 className="text-muted">abc@gmail.com</h5>
                          </div>
                          <div className="text-end">
                            <p className="mb-2">
                              Phone <strong className="text-primary"></strong>
                            </p>
                            <h5 className="text-muted">+91 9876543210</h5>
                          </div>
                        </div>
                        <div className="">
                          Music is an art form, social activity or cultural
                          activity whose medium is sound and silence. The common
                          elements of music are pitch (which governs melody and
                          harmony), rhythm (and its associated concepts tempo,
                          meter, and articulation), dynamics (loudness and
                          softness), and the sonic qualities of timbre and
                          texture (which are sometimes termed the "color" of a
                          musical sound). Different styles or types of music may
                          emphasize, de-emphasize or omit some of these
                          elements. Music is performed with a vast range of
                          instruments and with vocal techniques ranging from
                          singing to rapping, and there are solely instrumental
                          pieces, solely vocal pieces and pieces that combine
                          singing and instruments.
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer>
            {selectedArtist.status !== "Approved" && (
              <Button
                variant="success"
                onClick={() =>
                  handleChangeStatus(selectedArtist.artistId, "Approved")
                }
              >
                Approve
              </Button>
            )}

            {selectedArtist.status !== "Pending" && (
              <Button
                variant="warning"
                onClick={() =>
                  handleChangeStatus(selectedArtist.artistId, "Rejected")
                }
              >
                Add to Pending
              </Button>
            )}

            {selectedArtist.status !== "Rejected" && (
              <Button
                variant="danger"
                onClick={() =>
                  handleChangeStatus(selectedArtist.artistId, "Rejected")
                }
              >
                Reject
              </Button>
            )}
          </Modal.Footer> */}
        </Modal>
      )}
    </Col>
  );
};

export default ReleaseReq;
