import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FaPlay, FaPause } from "react-icons/fa";
import "./AlbumDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const AlbumDetails = () => {
  const { id } = useParams();
  const [album, setalbum] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const progressBarRef = useRef(null);
  const animationRef = useRef(null); // For smooth animation
  const [albums, setAlbums] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        // Fetch artistId from localStorage
        const artistProfile = JSON.parse(localStorage.getItem("profile"));
        const artistId = artistProfile?.artistId;

        if (!artistId) {
          // setError("Artist ID not found");
          return;
        }

        // Call the API to fetch albums by the artist
        const response = await axios.get(
          `https://fttunes.com/api/artists/${artistId}/albums`
        );
        setAlbums(response.data.data); // Assuming API returns albums in `data.data`
        // setLoading(false);
      } catch (err) {
        // setError("Failed to fetch albums");
        // setLoading(false);
      }
    };

    fetchAlbums();
  }, []);

  useEffect(() => {
    const fetchAlbumdata = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/albums/${id}`
        );
        console.log("response", response.data.album);
        setalbum(response.data.album);
        setLoading(false);
      } catch (err) {
        console.log("error while fetching album data", err);
        setError("Failed to fetch albums");
        setLoading(false);
      }
    };
    fetchAlbumdata();
  }, [id]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        cancelAnimationFrame(animationRef.current); // Stop animation
      } else {
        audioRef.current.play();
        animationRef.current = requestAnimationFrame(whilePlaying); // Start animation
      }
      setIsPlaying(!isPlaying);
    }
  };

  const whilePlaying = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration || 0);
      animationRef.current = requestAnimationFrame(whilePlaying); // Keep updating
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  const getProgressBarWidth = () => {
    if (duration > 0) {
      return `${(currentTime / duration) * 100}%`;
    }
    return "0%";
  };

  const handleSongEnd = () => {
    setIsPlaying(false); // Set play/pause button to "Play" once the song ends
  };

  const handleProgressBarClick = (e) => {
    if (progressBarRef.current && audioRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const clickPosition = e.clientX - rect.left;
      const clickPercentage = clickPosition / rect.width;
      const newTime = clickPercentage * duration;
      audioRef.current.currentTime = newTime; // Set the new time
      setIsPlaying(true);
      audioRef.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
      setCurrentTime(newTime); // Update the UI to reflect the new time
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="content-body" style={{ padding: "0px" }}>
      <section className="album-banner">
        <Container fluid className="container-fulid">
          <div className="album-image">
            <div
              className="back-img"
              style={{
                backgroundImage: `url('https://fttunes.com/api/${album.uploadPoster}')`,
              }}
            ></div>
          </div>
          <Row>
            <Col lg={5}></Col>
            <Col lg={7}>
              <div className="album-details">
                <div className="title">
                  <h2 className="h1-title">
                    <mark>{album.songName}</mark>
                  </h2>
                  <span className="small-text">2M monthly listeners</span>
                </div>
                <div className="album-songs-detail">
                  <div className="popular-songs">
                    <h4>popular songs</h4>
                    <ul className="single-albums-list-box">
                      {albums
                        .filter((albumItem) => parseInt(albumItem.id) !== parseInt(id))
                        .slice(0, 3)
                        .map((albumItem, index) => (
                          <li
                            className="music-list-box current_play"
                            onClick={() => navigate(`/fttrack/${albumItem.id}`)}
                          >
                            <div className="songs-name">
                              <div
                                className="back-img"
                                style={{
                                  backgroundImage: `url('https://fttunes.com/api/${albumItem.uploadPoster}')`,
                                }}
                              ></div>
                              <h3 className="h3-title">{albumItem.songName}</h3>
                            </div>
                            <div className="audio-box">
                              <div className="music-controls play play_btn">
                                <div
                                  data-id="3"
                                  className="music-controls-item"
                                >
                                  <FaPlay className="music-controls-item--icon play-icon" />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      {/* <li className="music-list-box">
                        <div className="songs-name">
                          <div
                            className="back-img"
                            style={{
                              backgroundImage:
                                "url('https://html.geekcodelab.com/spiffyplay//assets/images/austin-neill.jpg')",
                            }}
                          ></div>
                          <h3 className="h3-title">I Think He Knows</h3>
                        </div>
                        <div className="audio-box">
                          <div className="music-controls play play_btn">
                            <div data-id="4" className="music-controls-item">
                              <FaPlay className="music-controls-item--icon play-icon" />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="music-list-box">
                        <div className="songs-name">
                          <div
                            className="back-img"
                            style={{
                              backgroundImage:
                                "url('https://html.geekcodelab.com/spiffyplay//assets/images/austin-neill.jpg')",
                            }}
                          ></div>
                          <h3 className="h3-title">Cruel Summer</h3>
                        </div>
                        <div className="audio-box">
                          <div className="music-controls play play_btn">
                            <div data-id="2" className="music-controls-item">
                              <FaPlay className="music-controls-item--icon play-icon" />
                            </div>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>

                  <div className="similar-artists">
                    <h4>Singers (artists)</h4>
                    <ul>
                      {JSON.parse(album.mainArtists).map((singer, index) => (
                        <li>
                          <div className="songs-name similar-artists-box">
                            <a>
                              <div
                                className="back-img"
                                style={{
                                  backgroundImage:
                                    "url('/Images/cartoon.png')",
                                }}
                              ></div>
                              <div className="similar-artists-text">
                                <h3 className="h3-title">{singer.name}</h3>
                                <span className="small-text">
                                  1.9M monthly listeners
                                </span>
                              </div>
                            </a>
                          </div>
                        </li>
                      ))}
                      {/* <li>
                        <div className="songs-name similar-artists-box">
                          <a href="javascript:void(0)">
                            <div
                              className="back-img"
                              style={{
                                backgroundImage:
                                  "url('https://html.geekcodelab.com/spiffyplay//assets/images/slim-emcee.jpg')",
                              }}
                            ></div>
                            <div className="similar-artists-text">
                              <h3 className="h3-title">Slim Emcee</h3>
                              <span className="small-text">
                                1.8M monthly listeners
                              </span>
                            </div>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="songs-name similar-artists-box">
                          <a href="javascript:void(0)">
                            <div
                              className="back-img"
                              style={{
                                backgroundImage:
                                  "url('https://html.geekcodelab.com/spiffyplay//assets/images/joshua-fuller.jpg')",
                              }}
                            ></div>
                            <div className="similar-artists-text">
                              <h3 className="h3-title">Joshua Fuller</h3>
                              <span className="small-text">
                                1.7M monthly listeners
                              </span>
                            </div>
                          </a>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div
                  className="music_content"
                  style={{ marginBottom: "100px" }}
                >
                  <div className="music-info">
                    <h2 className="music-name">
                      <b>
                        <mark>{album.songName}</mark>
                      </b>
                    </h2>
                    <p className="music-artist">{album.singerName}</p>
                  </div>
                  <audio
                    ref={audioRef}
                    id="audio1"
                    data-id={album.id}
                    src={`https://fttunes.com/api/${album.uploadAudio}`}
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={handleSongEnd}
                    onLoadedMetadata={handleTimeUpdate}
                  ></audio>
                  <div
                    className="music-progress"
                    onClick={handleProgressBarClick}
                    ref={progressBarRef}
                  >
                    <div
                      id="progress-bar"
                      className="music-progress-bar"
                      style={{ width: getProgressBarWidth() }}
                    ></div>
                    <div className="music-progress__time">
                      <span className="music-progress__time-item music-current-time">
                        {formatTime(currentTime)}
                      </span>
                      <span className="music-progress__time-item music-duration-time">
                        {formatTime(duration)}
                      </span>
                    </div>
                  </div>
                  <div className="music-controls music-controls-single-albums">
                    <div
                      className="music-controls-item play"
                      onClick={togglePlayPause}
                    >
                      {isPlaying ? (
                        <FaPause
                          className="music-controls-item--icon play-icon"
                          style={{ zIndex: "9" }}
                        />
                      ) : (
                        <FaPlay
                          className="music-controls-item--icon play-icon"
                          style={{ zIndex: "9" }}
                        />
                      )}
                      <div className="play-icon-background"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default AlbumDetails;
